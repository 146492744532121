import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const renderCheckbox = ({ input, label, disabled }) => (
    <div className="checkbox">
        <FormControlLabel
            control={
                <div className="checkbox__wrapper">
                    <Checkbox disabled={disabled} checked={input.value ? true : false} onChange={input.onChange} sx={{ margin: '0 1rem' }} />
                </div>
            }
            label={label}
        />
    </div>
);
export default renderCheckbox;
