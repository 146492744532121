import { LoadingScreen } from '@src/components';
import Layout from '@src/components/layout/Layout';
import PrivateRoute from '@src/contexts/auth/protectedRoute';
import About from '@src/pages/about';
import Conditions from '@src/pages/conditions';
import PrivacyCookie from '@src/pages/privacyCookie';
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

const Success = lazy(() => import('@src/pages/success/index'));
const NotFound404 = lazy(() => import('@src/pages/notFound'));
const Contact = lazy(() => import('@src/pages/contact/index'));
const Journey = lazy(() => import('@src/pages/journey/index'));
const Paperless = lazy(() => import('@src/pages/paperless/index'));
const Login = lazy(() => import('@src/pages/login/index'));
const Dashboard = lazy(() => import('@src/pages/dashboard/index'));

const Router = () => {
    return (
        <>
            <Suspense fallback={<LoadingScreen />}>
                <Switch>
                    <Route exact={true} path="/">
                        <Layout>
                            <Suspense fallback={<LoadingScreen />}>
                                <Login />
                            </Suspense>
                        </Layout>
                    </Route>
                    <PrivateRoute exact={true} path="/new-contract">
                        <Layout>
                            <Suspense fallback={<LoadingScreen />}>
                                <Journey />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path="/dashboard">
                        <Layout>
                            <Suspense fallback={<LoadingScreen />}>
                                <Dashboard />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path="/paperless">
                        <Layout>
                            <Suspense fallback={<LoadingScreen />}>
                                <Paperless />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute exact={true} path="/success">
                        <Layout>
                            <Suspense fallback={<LoadingScreen />}>
                                <Success />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <Route exact={true} path="/about">
                        <Layout>
                            <Suspense fallback={<LoadingScreen />}>
                                <About />
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route exact={true} path="/contact">
                        <Layout>
                            <Suspense fallback={<LoadingScreen />}>
                                <Contact />
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route exact={true} path="/privacy-cookie">
                        <Layout>
                            <Suspense fallback={<LoadingScreen />}>
                                <PrivacyCookie />
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route exact={true} path="/conditions">
                        <Layout>
                            <Suspense fallback={<LoadingScreen />}>
                                <Conditions />
                            </Suspense>
                        </Layout>
                    </Route>
                    <Route path="*">
                        <Layout>
                            <Suspense fallback={<LoadingScreen />}>
                                <NotFound404 />
                            </Suspense>
                        </Layout>
                    </Route>
                </Switch>
            </Suspense>
        </>
    );
};

export default Router;
