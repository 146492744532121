// import { reduxForm } from 'redux-form';
import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
// import cookie from 'react-cookies';

const Conditions = () => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');
    const language = localStorage.getItem('language');
    /* FUNCTIONS */

    /* API FUNCTIONS */

    /* USEEFFECTS */

    return (
        <Box sx={{ fontSize: '.9rem', margin: '2rem auto', maxWidth: '700px', backgroundColor: '#F2F2F4', padding: '2rem' }}>
            <article>
                <Typography variant="h5" component="h1" sx={{ textAlign: 'center' }}>
                    {t('conditions.title')}
                </Typography>
                <p>{t('conditions.text')}</p>
                {language !== 'en' ? (
                    <Box sx={{ margin: '1rem', textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                        <Link sx={{ margin: '.5rem' }} href={t('conditions.link_href_mobile')} target="_blank" rel="noreferrer">
                            {t('conditions.link_text_mobile')}
                        </Link>
                        <Link sx={{ margin: '.5rem' }} href={t('conditions.link_href_bwg')} target="_blank" rel="noreferrer">
                            {t('conditions.link_text_bwg')}
                        </Link>
                    </Box>
                ) : null}
            </article>
        </Box>
    );
};

export default Conditions;

// export default reduxForm({
//     form: '{{form}}',
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,
//     touchOnBlur: false
// })(Conditions)
