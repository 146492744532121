import { SelectListItem } from '@src/interfaces/RootState';

class CountryModel {
    name;
    value;
    label;
    constructor(item) {
        this.name = item.name;
        this.value = item.id;
        this.label = item.name;
    }
}

export default CountryModel;
