import React from 'react';
import i18next from 'i18next';
import { config as i18NextConfig } from '../translations/index';
import store from '../redux/store';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import MainWrapper from '@src/components/layout/mainWrapper';
import Router from './router';
import { AuthProvider } from '@src/contexts/auth/authProvider';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from '@src/components/form/theme';
// Init i18next
i18next.init(i18NextConfig);

export const App = () => {
    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <CssBaseline />
                <ThemeProvider theme={theme}>
                    <MainWrapper>
                        <AuthProvider>
                            <BrowserRouter>
                                <Router />
                            </BrowserRouter>
                        </AuthProvider>
                    </MainWrapper>
                </ThemeProvider>
            </I18nextProvider>
        </Provider>
    );
};
