// import { reduxForm } from 'redux-form';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');

    /* FUNCTIONS */

    /* API FUNCTIONS */

    /* USEEFFECTS */

    return (
        <Box sx={{ fontSize: '.9rem', margin: '2rem auto', maxWidth: '700px', backgroundColor: '#F2F2F4', padding: '2rem' }}>
            <article>
                <Typography variant="h5" component="h1" sx={{ textAlign: 'center' }}>
                    {t('about.title')}
                </Typography>
                <p>{t('about.text1')}</p>
                <p>{t('about.text2')}</p>
            </article>
        </Box>
    );
};

export default About;

// export default reduxForm({
//     form: '{{form}}',
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,
//     touchOnBlur: false
// })(About)
