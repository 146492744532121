import axiosInstance from '../http/axiosSetup';

export default class UnitService {
    static warrantyList = (queryParams) =>
        axiosInstance
            .get(`/common-data-managements/warranty-types`, { params: queryParams })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static productBrandList = (queryParams) =>
        axiosInstance
            .get(`/common-data-managements/product-brands`, {
                params: queryParams,
            })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static productGroupList = (queryParams) =>
        axiosInstance
            .get(`/common-data-managements/product-groups`, {
                params: queryParams,
            })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    // static employeeList = (body) =>
    //     axiosInstance
    //         .post(`employee/list`, body)
    //         .then((res) => {
    //             return res;
    //         })
    //         .catch((err) => {
    //             return err.response;
    //         });
    static employeeList = (body) => {
        return axiosInstance
            .get(`/shop-managements/shops/${body.partnerId}/${body.shopId}/employees`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    };
    static shopDetails = (payload) =>
        axiosInstance
            .get(`/shop-managements/shops/${payload?.partnerId}/${payload?.shopId}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static shopList = (payload) =>
        axiosInstance
            .get(`/shop-managements/shops?partnerIds=${payload?.partnerId}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    // static bankList = (body) =>
    //     axiosInstance
    //         .post('/bank/list', body)
    //         .then((res) => {
    //             return res;
    //         })
    //         .catch((err) => {
    //             return err.response;
    //         });
    static bankListNew = (bankCode, countryId) =>
        axiosInstance
            .get(`/common-data-managements/bank/${bankCode}/${countryId}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static bankCountryList = () =>
        axiosInstance
            .get('/bank/country/list')
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static countryList = () =>
        axiosInstance
            .get('/common-data-managements/countries')
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static languageList = () =>
        axiosInstance
            .get('/common-data-managements/languages')
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static partnerList = () =>
        axiosInstance
            .get(`partner-managements/partners`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
}
