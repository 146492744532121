import { Box, CardMedia, Container, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Footer(props) {
    let brand;
    const { t } = useTranslation('common');
    const lang = localStorage.getItem('language');
    return (
        <Box
            sx={{
                // height: '80px',
                // position: 'fixed',
                bottom: '0',
                width: '100%',
                flex: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                // backgroundColor: '#111111',
            }}
        >
            <Box sx={{ margin: '2rem auto', padding: '0 2rem', display: 'flex', width: '250px', alignItems: 'center' }}>
                <CardMedia
                    sx={{ maxHeight: 'auto', width: '100%', maxWidth: '90%', margin: 'auto', objectFit: 'contain' }}
                    component="img"
                    alt="benefit hub"
                    height="100%"
                    image="images/logo_white.png"
                />
            </Box>
            <Box
                sx={{
                    padding: '.5rem 1rem',
                    backgroundColor: '#111111',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    height: 'fit-content',
                    maxHeight: { xs: 'fit-content', md: '50px' },
                    flexDirection: { xs: 'column', md: 'row' },
                    alignSelf: 'self-end',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'center' } }}>
                    <MenuItem className="footer__link">
                        <Link to={'/about'}>{t('layout.about_us')}</Link>
                    </MenuItem>
                    <MenuItem className="footer__link">
                        <Link to={'/contact'}>{t('layout.contact_us')}</Link>
                    </MenuItem>
                    <MenuItem className="footer__link">
                        <Link to={'/privacy-cookie'}>{t('layout.privacy_cookie')}</Link>
                    </MenuItem>
                    <MenuItem className="footer__link">
                        <Link to={'conditions'}>{t('layout.conditions')}</Link>
                    </MenuItem>
                </Box>

                <Box sx={{ color: '#acb4bf' }}>Copyright &#169; SPB Benelux</Box>
            </Box>
        </Box>
    );
}
