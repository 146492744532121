/* eslint-disable no-console */
import React, { ReactNode, useContext, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router-dom';

import { REFRESH_TOKEN } from '@src/redux/auth/actions';
import { AuthContext } from './authContext';

interface PrivateRouteProps {
    children: ReactNode;
    exact?: boolean;
    props?: any;
    path?: string;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
    /* STATES */

    /* VARIABLES */
    const dispatchStore = useDispatch();
    const {
        authState: { isAuthenticated },
    } = React.useContext<any>(AuthContext);
    const { authDispatch } = useContext<any>(AuthContext);
    const history = useHistory();

    /* FUNCTIONS */
    const logOut = () => {
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('token');
        authDispatch({ type: 'SIGNED_OUT' });
        history.replace('/');
    };

    const refreshToken = () => {
        dispatchStore({ type: REFRESH_TOKEN });
    };

    const handleLogout = () => {
        if (isAuthenticated) {
            logOut();
        }
    };

    const { getRemainingTime } = useIdleTimer({
        // timeout: 10000, // 10 sec for the test
        timeout: 1000 * 1800 * 1, // 30 minutes
        onIdle: handleLogout,
        debounce: 500,
    });

    /* USEEFFECTS */
    useEffect(() => {
        dispatchStore({ type: REFRESH_TOKEN });
        const tokenRefreshInterval = setInterval(() => {
            /** Token Refresh */
            console.warn('Token refresh');
            refreshToken();
            // }, 3000); // sec for the test
        }, 3 * 60 * 1000); // 3 minutes
        return () => {
            clearInterval(tokenRefreshInterval);
        };
    }, []);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
