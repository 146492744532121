import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as SelectInterfaces from '@interfaces/SelectInterface';

interface SelectFieldProps {
    options: SelectInterfaces.SelectList;
    meta: Record<string, string | boolean>;
    defaultValue?: string;
    value: any;
    placeholder: string;
    id?: string;
    onChange: (value: SelectInterfaces.SelectListItem) => void;
}

type RenderSelectFieldProps = {
    input?: any;
    id?: string;
    options: SelectInterfaces.SelectList;
    meta: Record<string, string | boolean>;
    className?: string;
    placeholder: string;
    defaultValue?: Record<string, string | number> | string | number;
};

const SelectField: React.FC<SelectFieldProps> = ({ id, onChange, value, options, placeholder, meta }) => {
    // Function that runs when select an option
    const handleChange = (option) => {
        onChange(option);
    };

    // Iterate through options
    const optionItems = options?.map((item, index) => {
        return (
            <MenuItem key={`${item.name}-${index}`} value={item.value}>
                {item.name}
            </MenuItem>
        );
    });

    return (
        <FormControl onChange={handleChange} fullWidth>
            <InputLabel error={meta.touched && meta.invalid ? true : false}>{placeholder}</InputLabel>
            <Select error={meta.touched && meta.invalid ? true : false} onChange={handleChange} labelId={id} id={id} value={value} defaultValue="en" label={placeholder}>
                {optionItems}
            </Select>
        </FormControl>
    );
};

const renderSelectField: React.FC<RenderSelectFieldProps> = ({ input, meta, options, placeholder, id, defaultValue }) => (
    <div
        className={
            meta.touched && meta.error
                ? 'form__form-group-input-wrap form__form-group-input-wrap--error selectHasError z-1000'
                : 'form__form-group-input-wrap form__form-group-input-wrap--error z-1000'
        }
    >
        <SelectField {...input} options={options} placeholder={placeholder} defaultValue={defaultValue} meta={meta} id={id} />
        {meta.touched && meta.error && (
            <span className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">{meta.error}</span>
        )}
    </div>
);

export default renderSelectField;
