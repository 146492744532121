import ReactDOM from 'react-dom';
// import { reduxForm } from 'redux-form';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import RootState from '@src/interfaces/RootState';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const LoadingScreen = () => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');
    const isLoading = useSelector((state: RootState) => state.ui.isLoading);

    /* FUNCTIONS */
    const content = (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CircularProgress color="inherit" sx={{ margin: '1rem auto' }} />
                <Typography align="center" className="loading__title">
                    {/* {t('common.please_wait')} */}
                </Typography>
            </Box>
        </Backdrop>
    );

    /* USEEFFECTS */

    return ReactDOM.createPortal(content, document.getElementById('loader-hook')!);
};

export default LoadingScreen;

// export default reduxForm({
//     form: '{{form}}',
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,
//     touchOnBlur: false
// })(index)
